import React, { useState, useEffect } from "react";
import { useParams, Link } from "react-router-dom";
import Spinner from "./Spinner";
import '../Components/custom.css';
import { getApiEndpoint } from "../services/api";

function Announcement() {
  const { id } = useParams();   // Extracts the post ID from the URL
  const [post, setPost] = useState(null);
  const [posts, setPosts] = useState([]); //  Sto re all posts
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  // Fetch the selected post
  useEffect(() => {
    fetch(getApiEndpoint(`getPost/${id}`))
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch the post.");
        }
        return response.json();
      })
      .then((data) => {
        setPost(data);
        setLoading(false);
      })
      .catch((err) => {
        setError(err.message);
        setLoading(false);
      });
  }, [id]);

  // Fetch all posts for the list
  useEffect(() => {
    fetch("https://vzcp-backend.dev/api/getLatestPosts")
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to fetch posts.");
        }
        return response.json();
      })
      .then((data) => setPosts(data))
      .catch((err) => setError(err.message));
  }, []);

  return (
    <div className="flex flex-col items-center min-h-screen p-4 bg-white sm:p-6">
      <h1 className="p-16 mt-8 text-3xl font-bold text-center text-gray-800 sm:text-lg md:text-2xl lg:text-5xl sm:mt-16">
        News and Updates
      </h1>

      {loading && (
        <Spinner />
      )}

      {post && (
        <div className="flex flex-col w-full max-w-screen-lg gap-8 lg:flex-row">
          {/* Main Article Content */}
          <article className="flex-1 p-6 prose-sm prose rounded-lg sm:prose lg:prose-lg xl:prose-xl">
            <figure>
              <img
                src={post.image_url}
                alt={post.title}
                className="rounded-lg shadow-md w-full object-cover max-h-[300px] sm:max-h-[400px]"
              />
              <h3 className="my-4 italic">{post.title}</h3>
            </figure>

            <article
              className="leading-relaxed text-gray-700"
              dangerouslySetInnerHTML={{ __html: post.description }}
            ></article>

            {/* <blockquote className="mt-4 italic">
              "{post.quote || "Embrace the beauty of change."}"
            </blockquote> */}


          </article>

          {/* Announcements Container */}
          <aside className="sticky w-full p-6 text-white border-l-2  lg:w-1/4 h-96 top-24 pl-10">
            <h2 className="mb-4 text-2xl font-semibold text-center text-black">
              Announcements
            </h2>
            <ul className="space-y-2 overflow-hidden overflow-y-auto text-lg max-h-60">
              {posts.length > 0 ? (
                posts.map((item) => (
                  item.id !== post.id && (
                    <li key={item.id}>
                      <Link
                        to={`/announcement/${item.id}`}
                        className="text-blue-600 hover:underline"
                      >
                        {item.title}
                      </Link>
                    </li>
                  )
                ))
              ) : (
                <p className="text-gray-600">No announcements available.</p>
              )}
            </ul>
          </aside>
        </div>
      )}
    </div>
  );
}

export default Announcement;
