import React, { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import { getApiEndpoint } from "../services/api";
function LawyerProfile() {
  const { id } = useParams();  // Extract the lawyer ID from the URL
  const [lawyer, setLawyer] = useState(null);
  const [loading, setLoading] = useState(true);

  // Fetch lawyer data from Laravel API
  useEffect(() => {
    fetch(getApiEndpoint(`getLawyer/${id}`))
      .then((response) => response.json())
      .then((data) => {
        setLawyer(data);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching lawyer:", error);
        setLoading(false);
      });
  }, [id]);

  return (
    <div className="bg-black text-white min-h-screen flex justify-center items-center p-6 md:p-12">
      {loading ? (
        <Spinner />
      ) : lawyer ? (
        <div className="flex flex-col lg:flex-row items-center lg:items-start gap-8 max-w-5xl">
          {/* Left Section - Image */}
          <div className="w-full mt-12 lg:w-1/3 flex justify-center">
            <img
              src={lawyer.image_url}
              alt={lawyer.name}
              className="rounded-full object-cover max-w-full h-auto"
            />
          </div>

          {/* Right Section - Text Content */}
          <div className="w-full lg:w-2/3 lg:pt-10 mt-5">
            <h1 className="Lawyer-name text-2xl md:text-3xl font-bold text-center lg:text-left">
               {lawyer.name}
            </h1>
            <p className="mb-6 text-lg text-center lg:text-left">
              {lawyer.role || "Partner"}
            </p>

            <p className="mb-4 lg:text-lg text-justify"  dangerouslySetInnerHTML={{ __html: lawyer.background }}></p>

            {/* Contact & Practices Section */}
            {/* <div className="bg-white text-black p-6 rounded-md mt-6 flex flex-col md:flex-row justify-between gap-4">
              <div>
                <h2 className="font-bold mb-2">Practices</h2>
                <ul className="list-disc list-inside">
                  {Array.isArray(lawyer.practices) && lawyer.practices.map((practice, index) => (
                    <li key={index}>{practice}</li>
                  ))}
                </ul>
              </div>
              <div>
                <h2 className="font-bold mb-2">Contact</h2>
                <p>{lawyer.email}</p>
                <p>{lawyer.contact_number}</p>
              </div>
            </div> */}


          </div>
        </div>
      ) : (
        <p className="text-red-500">Lawyer not found.</p>
      )}
    </div>
  );
}

export default LawyerProfile;
