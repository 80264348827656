import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Spinner from "./Spinner";
import {getApiEndpoint} from "../services/api";
import '../Components/OurTeam.css';

function OurTeam() {
    const [lawyers, setLawyers] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const navigate = useNavigate();

    useEffect(() => {
        const fetchLawyers = async () => {
            try {
            setIsLoading(true);
            const response = await fetch(getApiEndpoint('getLawyers'));
            
            if (!response.ok) {
                throw new Error("Failed to fetch lawyers");
            }
            const data = await response.json();
            setLawyers(data);
            } catch (error) {
            console.error("Error fetching lawyers:", error);
            } finally {
            setIsLoading(false);
            }
        };

        fetchLawyers();
    }, []);

    return (
        <div className="flex pt-32 bg-black min-h-screen">
            <div className="flex flex-col items-center w-full px-4 md:px-8 lg:px-16">

                <div className="flex flex-col items-center w-full text-center md:flex-row md:justify-center md:items-center mb-6">
                    <p className="Find-lawyer text-[#EFDA55] text-xl md:text-2xl font-roboto pr-0 md:pr-4">
                        Find a Lawyer
                    </p>
                    <hr className="Lawyers-Line mt-2 border-t-2 border-[#EFDA55] w-1/2" />
                </div>

                {isLoading ? (
                    <Spinner />

                ) : lawyers.length > 0 ? (
                    lawyers.map((lawyer) => (
                        <div key={lawyer.id} className="Lawyer flex flex-col md:flex-row space-y-4 md:space-y-0 md:space-x-10 mt-6 text-white py-4 px-4 items-center">
                            <div className="mr-0 md:mr-8">
                                <img
                                    src={lawyer.image_url}
                                    alt={lawyer.name}
                                    className="lawyer-img w-24 h-24 md:w-32 md:h-32 lg:w-40 lg:h-40 rounded-full rounded-full object-cover aspect-square"
                                />
                            </div>

                            <div className="Lawyer-info text-center md:text-left flex flex-col items-center md:items-start">
                                <h1 className="Lawyer-name font-roboto font-semibold mb-2 text-lg md:text-lg lg:text-2xl">{lawyer.name}</h1>
                                <p className="font-inter text-sm md:text-base lg:text-lg max-w-[800px] text-justify">{lawyer.summary}</p>
                                <button
                                    onClick={() => navigate(`/lawyer-profile/${lawyer.id}`)}
                                    className="border-2 border-[#EFDA55] text-white px-4 py-1 rounded-3xl hover:bg-[#EFDA55] hover:text-black transition duration-300 font-semibold font-roboto mt-4"
                                >
                                    Full Profile
                                </button>
                            </div>
                        </div>
                    ))
                ) : (
                    <p className="text-white mt-8">No lawyers available at the moment.</p>
                )}
            </div>
        </div>
    );
}

export default OurTeam;
