import { Link } from 'react-router-dom';
import { useState, useEffect, useRef } from 'react';
import { Bars3Icon, XMarkIcon } from '@heroicons/react/24/outline';
import Logo1 from "../Image/W-logo1.png";

const Navigationbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const timeoutRef = useRef(null);

  useEffect(() => {
    return () => clearTimeout(timeoutRef.current);
  }, []);

  const toggleMenu = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <nav className="bg-black text-white fixed w-full top-0 left-0 z-10 shadow-md">
      <div className="mx-auto px-4 py-2 sm:px-6 lg:px-8">
        <div className="flex justify-between items-center">
          {/* Title Small Devices */}
        <div className="flex items-center w-full justify-center md:hidden">
          <span className="text-lg text-center  font-bold">VZCP & Associates
          </span>
        </div>

          {/* Logo */}
          <Link to="/" className="hidden md:flex items-center">
            <img src={Logo1} alt="Logo" className="object-contain h-20 w-40" />
          </Link>

          {/* Desktop Menu */}
          <div className="hidden md:flex justify-end items-center w-full space-x-7 lg:text-lg md:text-sm font-serif">
            <Link to="/" className="hover:text-[#BC9C22]">Home</Link>

            <Link
              to="/about-us"
              className="relative group hover:text-[#BC9C22] transition duration-300"
            >
              About Us

              <ul className="absolute list-none p-2 left-1/2 transform -translate-x-1/2 mt-2 w-48 bg-white text-black shadow-lg rounded-lg opacity-0 group-hover:opacity-100 group-hover:translate-y-2 transition-all duration-300 ease-in-out z-10">
                <li>
                  <Link
                    to="/about-us#mission"
                    className="block py-1 px-2 hover:bg-[#BC9C22] hover:text-white rounded-lg transition"
                  >
                    Mission & Vision
                  </Link>
                </li>
                <li>
                  <Link
                    to="/about-us#history"
                    className="block py-1 px-2 hover:bg-[#BC9C22] hover:text-white rounded-lg transition"
                  >
                    History
                  </Link>
                </li>
              </ul>
            </Link>


            <Link to="/our-team" className="hover:text-[#BC9C22]">Our Team</Link>
            <Link to="/our-services" className="hover:text-[#BC9C22]">Our Services</Link>
            <Link to="/news-updates" className="hover:text-[#BC9C22]">News & Updates</Link>

            <Link to="/contact" className="border-2 border-[#EFDA55] px-4 py-1 rounded-3xl hover:bg-[#EFDA55] hover:text-black transition font-sans">
              Connect with us!
            </Link>
          </div>

          {/* Mobile Menu Button */}
          <button onClick={toggleMenu} className="md:hidden text-white">
            {isOpen ? <XMarkIcon className="h-6 w-6" /> : <Bars3Icon className="h-6 w-6" />}
          </button>
        </div>
      </div>

      {/* Mobile Menu */}
      {isOpen && (
        <div className="md:hidden bg-black text-white transition-all ease-in-out">
          <div className="space-y-4 px-4 py-3">
            <Link to="/" className="block hover:text-[#BC9C22]" onClick={toggleMenu}>
              Home
            </Link>
            <Link to="/about-us" className="block hover:text-[#BC9C22]" onClick={toggleMenu}>
              About Us
            </Link>
            <Link to="/our-team" className="block hover:text-[#BC9C22]" onClick={toggleMenu}>
              Our Team
            </Link>
            <Link to="/our-services" className="block hover:text-[#BC9C22]" onClick={toggleMenu}>
              Our Services
            </Link>
            <Link to="/news-updates" className="block hover:text-[#BC9C22]" onClick={toggleMenu}>
              News & Updates
            </Link>
            <Link to="/contact" className="block bg-[#BC9C22] text-white px-4 py-2 rounded-3xl" onClick={toggleMenu}>
              Connect with us!
            </Link>
          </div>
        </div>
      )}
    </nav>
  );
};

export default Navigationbar;
