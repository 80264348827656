import React, { useState, useEffect, useRef} from 'react';
import { Link } from 'react-router-dom';
import '../Components/custom.css';
import { getApiEndpoint } from '../services/api';

const OurServices = () => {
  const [data, setData] = useState([]);
  const [selectedService, setSelectedService] = useState(null);
  const serviceRef = useRef(null);

  useEffect(() => {
    // Fetch services from API
    fetch(getApiEndpoint('getServices'))
      .then(response => response.json())
      .then(data => setData(data))
      .catch(error => console.error('Error fetching services:', error));
  }, []);

  useEffect(() => {
    if (selectedService && serviceRef.current) {
      serviceRef.current.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  }, [selectedService]);

  return (
    <div className="bg-black min-h-svh flex flex-col items-center">
      <div className="text-center mb-8 px-4 mt-40">
        <h1 className="Whatwedo-tagline text-4xl font-bold font-roboto mb-4 sm:mb-6">AREAS OF PRACTICE</h1>
        <hr className="Practices-line border-t-2 border-[#EFDA55] mx-auto mb-4 w-1/2 sm:w-1/3" />
        <ul className="bg-white bg-opacity-15 backdrop-blur-md grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 list-none p-6 shadow-lg rounded-lg">
          {data.map((service) => (
            <li key={service.id} className="mb-2">
              <Link
                onClick={() => setSelectedService(service)}
                to="#"
                className="text-white block text-lg font-inter transition duration-200 md:p-2 lg:p-4 border-2 rounded-lg shadow hover:bg-[#EFDA55] hover:text-black"
              >
                {service.name}
              </Link>
            </li>
          ))}
        </ul>
      </div>
      {selectedService && (
        <div ref={serviceRef} className=" mt-8 w-3/4 mb-[100px]">
          <div className='items-center h-full text-white'>
            <div className='mb-6'>
              <img src={selectedService.image_url} className='max-h-[300px] w-full object-cover' alt='Service' />
            </div>
            <h1 className='Practices-tagline mt-4 text-3xl font-roboto'>{selectedService.name}</h1>
        
            <p className="text-base leading-loose font-inter text-justify mt-4" dangerouslySetInnerHTML={{ __html: selectedService.description }}></p>
          </div>
        </div>
      )}
    </div>
  );
};

export default OurServices;
