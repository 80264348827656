import React, { useEffect, useState } from "react";
import '../Components/Home.css';
import { Link } from 'react-router-dom';
import { getApiEndpoint } from "../services/api";

function Home() {
  const [data, setData] = useState("");

  useEffect(() => {
    fetch(getApiEndpoint("getSetting/home-section-1"))
      .then(response => response.json())
      .then(data => setData(data || "Content not available"))
      .catch(error => console.error("Error fetching content:", error));
  }, []);

  return (
    <>
      <div className="App-header min-h-screen flex items-center justify-center px-4 sm:px-8">
        <div className="flex text-white flex-col items-center max-w-5xl">
          <h1 className="Home-tagline text-4xl md:text-7xl font-roboto font-semibold text-center mb-4">
            {data.title}
          </h1>

          <p className="font-inter text-base text-justify w-full sm:w-3/4 lg:w-1/2">
            {data.description}
          </p>

          <div className="flex flex-col sm:flex-row justify-between items-center mt-6 space-y-4 sm:space-y-0 sm:space-x-6">
            <Link to="/contact"
              className="relative rounded-3xl overflow-hidden bg-black px-6 py-3 text-white text-lg 
                     font-serif shadow-2xl transition-all hover:before:w-full hover:text-black
                     before:absolute before:bottom-0 before:left-0 before:top-0 before:z-0
                     before:h-full before:w-0 before:bg-[#edc967] before:transition-all 
                     before:duration-500"
            >
              <span className="relative z-10">Get in Touch!</span>
            </Link>

            <div className="flex space-x-4">
              {/* Facebook */}
              <a
                href="https://www.facebook.com/profile.php?id=61566559956236"
                className="flex items-center justify-center bg-black hover:bg-[#edc967] 
                       text-white rounded-full p-2 transition duration-200"
              >
                <span className="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-white hover:[&>svg]:fill-black">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512">
                    <path d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z" />
                  </svg>
                </span>
              </a>

              {/* Email 
              <a
                href="mailto:admin@vzclaw.com"
                className="flex items-center justify-center bg-black hover:bg-[#edc967] 
                         text-white rounded-full p-2 transition duration-200"
                >
                  <span className="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-white hover:[&>svg]:fill-black">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                  <path d="M502.3 190.8c3.9-3.1 9.7-.2 9.7 4.7v185.6c0 26.5-21.5 48-48 48H48c-26.5 0-48-21.5-48-48V195.5c0-4.9 5.8-7.8 9.7-4.7 22.4 17.4 52.1 39.7 154.1 113.1 21.1 15.1 56.7 47.7 92.2 47.7 35.7 0 72-33 92.2-47.7 102-73.4 131.7-95.7 154.1-113.1zM256 320c23.3 0 56.7-31 73.4-42.7 132.1-94.6 140.3-100.4 173.8-126.6 5.8-4.5 9.2-11.5 9.2-18.9V64c0-26.5-21.5-48-48-48H48C21.5 16 0 37.5 0 64v67.8c0 7.4 3.4 14.4 9.2 18.9 33.5 26.2 41.7 32 173.8 126.6C199.3 289 232.7 320 256 320z" />
                 </svg>
                 </span>
                  </a> */}

              {/* Messenger */}
              <a
                href="https://m.me/vzcp2019"
                className="flex items-center justify-center bg-black hover:bg-[#edc967] 
                       text-white rounded-full p-2 transition duration-200"
              >
                <span className="[&>svg]:h-7 [&>svg]:w-7 [&>svg]:fill-white hover:[&>svg]:fill-black">
                  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512">
                    <path d="M256.6 8C116.5 8 8 110.3 8 248.6c0 72.3 29.7 134.8 78.1 177.9 8.4 7.5 6.6 11.9 8.1 58.2A19.9 19.9 0 0 0 122 502.3c52.9-23.3 53.6-25.1 62.6-22.7C337.9 521.8 504 423.7 504 248.6 504 110.3 396.6 8 256.6 8zm149.2 185.1l-73 115.6a37.4 37.4 0 0 1 -53.9 9.9l-58.1-43.5a15 15 0 0 0 -18 0l-78.4 59.4c-10.5 7.9-24.2-4.6-17.1-15.7l73-115.6a37.4 37.4 0 0 1 53.9-9.9l58.1 43.5a15 15 0 0 0 18 0l78.4-59.4c10.4-8 24.1 4.5 17.1 15.6z" />
                  </svg>
                </span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Home;
