import React, { useEffect, useState } from "react";
import { getApiEndpoint } from "../services/api";

function Contact() {
  const [contactInfo, setContactInfo] = useState([]);
  const [loading, setLoading] = useState(true);

  // Fetch contact information from API
  const fetchContactInfo = async () => {
    try {
      const response = await fetch(getApiEndpoint(`getContacts`));
      const data = await response.json();
      setContactInfo(data); // Assuming the API returns an array of contact info
      setLoading(false);
    } catch (error) {
      console.error("Failed to fetch contact info:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchContactInfo();
  }, []);

  return (
    <div className="min-h-screen bg-black flex items-center justify-center py-10 px-6">
      <div className="max-w-5xl w-full grid grid-cols-1 sm:mt-16 md:grid-cols-2 gap-8">
        {/* Left Section - Address and Map */}
        <div className="space-y-4">
          <h1 className="Contact-tagline text-4xl font-bold text-gray-800">Connect with Us</h1>
          <p className="text-lg text-white">
            65 F. Pasco Avenue, Santolan, Pasig City, 1610
          </p>

          <div className="bg-white p-6 shadow-md shadow-gray-50 rounded-lg">
            <h2 className="text-2xl font-semibold mb-4 text-gray-800">Contact Info</h2>
            {loading ? (
              <p className="text-gray-700">Loading contact information...</p>
            ) : (
              <ul className="space-y-3 list-none text-lg text-gray-700">
                {contactInfo.map((info, index) => (
                  <li key={index}>
                    <strong>{info.name}:</strong>{" "}
                    {info.is_link ? (
                      <a
                        href={info.contact}
                        target="_blank"
                        rel="noopener noreferrer"
                        className="text-blue-600 hover:underline"
                      >
                        {info.contact}
                      </a>
                    ) : (
                      <span>{info.contact}</span>
                    )}
                  </li>
                ))}
              </ul>
            )}
          </div>
        </div>

        {/* Right Section - Google Map */}
        <div className="Maplocation">
          <iframe
            title="Google Map Location"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3860.657956717256!2d121.08234030000001!3d14.618550599999999!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3397b9b5fef0b86f%3A0x59ba001b21373843!2sVelasco%20Zarate%20Centeno%20Pallones%20and%20Associates!5e0!3m2!1sen!2sph!4v1732710336427!5m2!1sen!2sph"
            width="100%"
            height="450"
            style={{ border: 0 }}
            allowFullScreen
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Contact;
