import "./App.css";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import AboutUs from "./Components/AboutUs";
import Home from "./Components/Home";
import Navigationbar from "./Components/Navigationbar";
import Contact from "./Components/Contact";
import OurTeam from "./Components/OurTeam";
import LawyerProfile from "./Components/LawyerProfile"; // Fixed the typo
import OurServices from "./Components/OurServices";
import Newsandupdate from "./Components/NewsandUpdate";
import Announcement from "./Components/Announcement";

function App() {
  return (
    <Router>
      <div className="App">
        <Navigationbar />

        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/about-us" element={<AboutUs />} />
          <Route path="/our-team" element={<OurTeam />} />
          <Route path="/lawyer-profile/:id" element={<LawyerProfile />} /> 
          <Route path="/our-services" element={<OurServices />} />
          <Route path="/news-updates" element={<Newsandupdate />} /> 
          <Route path="/announcement/:id" element={<Announcement />} />
          <Route path="/contact" element={<Contact />} />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
