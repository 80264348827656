import React, { useState, useEffect } from "react";
import { Link } from 'react-router-dom';
import { getApiEndpoint } from "../services/api";
import Logo1 from "../Image/BG 1.png";


function Newsandupdate() {
  const [news, setNews] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasNews, setHasNews] = useState(true);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  // Fetch news from Laravel API with pagination
  useEffect(() => {
    fetch(getApiEndpoint(`getPosts?page=${currentPage}`))
      .then((response) => response.json())
      .then((data) => {
        if (data.data && data.data.length === 0) {
          setHasNews(false);
        } else {
          setNews(data.data);  // Assuming 'data' contains the posts
          setTotalPages(data.meta?.last_page || 1);  // Assuming the response has pagination metadata
        }
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
        setHasNews(false);
      });
  }, [currentPage]); // Fetch data whenever currentPage changes

  // Handle page change
  const handlePageChange = (page) => {
    if (page >= 1 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  return (
    <div className="min-h-screen  text-white bg-gradient-to-b from-black to-gray-900">
      <section className="body-font">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col w-full mt-6 mb-20 text-center">
            <h2 className="mb-1 text-4xl font-bold tracking-widest title-font">News & Updates</h2>
            <h1 className="mt-2 text-lg font-light text-gray-400 title-font">
              Stay ahead with our latest buzz. We promise it’s worth your time!
            </h1>
          </div>

          {/* Loading State */}
          {/* {loading && (
            <p className="text-xl font-semibold text-center text-yellow-400 animate-pulse">
              Good things take time... Fetching updates!
            </p>
          )} */}

          {/* No News Available */}
          {!loading && !hasNews && (
            <p className="text-xl font-semibold text-center text-gray-300">
              No updates available at this time. Please check back soon for the latest news.
            </p>
          )}

          {/* News List with Skeleton Loader */}
          <div className="flex flex-wrap -m-4">
            {loading
              ?
              <div className="flex justify-center items-center w-full">
                  <div role="status">
                    <svg aria-hidden="true" class="w-8 h-8 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                      <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                    </svg>
                    <span class="sr-only">Loading...</span>
                  </div>
              </div>
              // [...Array(3)].map((_, index) => (
              //   <div key={index} className="p-4 md:w-1/3">
              //     <div className="flex flex-col h-full p-8 bg-white rounded-lg shadow-md">
              //       {/* Skeleton Loader for News Item */}
              //       <div className="flex items-center mb-3 animate-pulse">
              //         <div className="inline-flex items-center justify-center flex-shrink-0 w-10 h-10 mr-3 bg-gray-300 rounded-full"></div>
              //         <div className="h-6 w-2/3 bg-gray-300 rounded"></div>
              //       </div>
              //       <div className="flex-grow">
              //         <div className="h-4 mb-4 w-full bg-gray-300 rounded"></div>
              //         <div className="h-8 w-3/4 bg-gray-300 rounded"></div>
              //       </div>
              //     </div>
              //   </div>
              // ))
              : news.map((item) => (
                <div key={item.id} className="p-4 md:w-1/3">
                  <div class="max-w-sm bg-white border border-gray-200 rounded-lg shadow-sm dark:bg-gray-800 dark:border-gray-700">
                    
                    <img src={item.image_url} alt="Logo" className="object-contain rounded-t-lg" />
                    
                    <div class="p-5">
                    <h5 className="mb-2 text-2xl font-bold tracking-tight text-gray-900 dark:text-white">{item.title}</h5>
                    
                    <div className="flex-grow">
                      
                    <p class="mb-3 font-normal text-gray-700 dark:text-gray-400"
                        dangerouslySetInnerHTML={{
                          __html: item.description.length > 200
                            ? `${item.description.slice(0, 200)}...`
                            : item.description
                        }}
                      ></p>
                      <Link
                        to={`/Announcement/${item.id}`}
                        class="inline-flex items-center px-3 py-2 text-sm font-medium text-center text-white bg-blue-700 rounded-lg hover:bg-blue-800 focus:ring-4 focus:outline-none focus:ring-blue-300 dark:bg-blue-600 dark:hover:bg-blue-700 dark:focus:ring-blue-800"
                      >
                        Learn More
                        <svg
                          fill="none"
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          className="w-4 h-4 ml-2"
                          viewBox="0 0 24 24"
                        >
                          <path d="M5 12h14M12 5l7 7-7 7"></path>
                        </svg>
                      </Link>
                      </div>
                    </div>
                  </div>
                </div>
              ))}
          </div>

          {/* Pagination Controls */}
          {!loading && hasNews && totalPages > 1 && (
            <div className="flex justify-center mt-6">
              <button
                className="px-4 py-2 mx-2 text-white bg-gray-700 rounded-md"
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
              >
                Previous
              </button>
              <span className="text-xl font-semibold text-gray-300">
                Page {currentPage} of {totalPages}
              </span>
              <button
                className="px-4 py-2 mx-2 text-white bg-gray-700 rounded-md"
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage === totalPages}
              >
                Next
              </button>
            </div>
          )}
        </div>
      </section>
    </div>
  );
}

export default Newsandupdate;
