import React, { useEffect, useRef, useState } from "react";
import { useLocation } from "react-router-dom";
import "../Components/AboutUs.css";
import { getApiEndpoint } from "../services/api";

function AboutUs() {
  const aboutRef = useRef(null);
  const missionRef = useRef(null);
  const visionRef = useRef(null);
  const historyRef = useRef(null);
  const { hash } = useLocation();
  const [content, setContent] = useState({});

  // Fetch specific data from the API
  const fetchContent = async (code) => {
    try {
      const response = await fetch(getApiEndpoint(`getSetting/${code}`));
      const data = await response.json();
      setContent((prev) => ({
        ...prev,
        [code]: {
          description: data.description || "Content not available",
          title: data.title,
          image: data.image_url,
        },
      }));
    } catch (error) {
      console.error(`Error fetching ${code}:`, error);
    }
  };

  useEffect(() => {
    fetchContent("about-us-section-1"); // About Us
    fetchContent("about-us-section-2"); // Mission
    fetchContent("about-us-section-3"); // Vision
    fetchContent("about-us-section-4"); // History
  }, []);

  // Scroll to the appropriate section based on the hash
  useEffect(() => {
    if (hash === "" && aboutRef.current) {
      aboutRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#mission" && missionRef.current) {
      missionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#vision" && visionRef.current) {
      visionRef.current.scrollIntoView({ behavior: "smooth" });
    } else if (hash === "#history" && historyRef.current) {
      historyRef.current.scrollIntoView({ behavior: "smooth" });
    }
  }, [hash]);

  return (
    <section ref={aboutRef}>
      <div className="Container-AboutUs mx-auto pt-24 px-4 lg:px-8">
        <div className="flex flex-col items-center text-white">
          <img
            src={content["about-us-section-1"]?.image}
            className="h-64 w-full max-w-4xl object-cover rounded-xl shadow-lg shadow-cyan-100 mt-10"
            alt={content["about-us-section-1"]?.title || "About Us"}
          />
          <h1 className="AboutUs-tagline text-4xl md:text-5xl text-center font-roboto mt-5">
            {content["about-us-section-1"]?.title || "About Us"}
          </h1>
          <hr className="w-20 border-t-2 border-[#EFDA55] my-4" />
          <p
            className="text-sm md:text-base leading-loose text-justify font-roboto w-full max-w-4xl px-4"
            dangerouslySetInnerHTML={{
              __html: content["about-us-section-1"]?.description || "Loading content...",
            }}
          />
        </div>

        {/* Mission Section */}
        <section id="mission" ref={missionRef} className="pt-12">
          <div className="py-8">
            <div className="flex flex-col items-center text-white px-4 py-10">
              <div className="flex justify-center items-center w-full max-w-7xl space-x-2">
                <p className="Mission-tagline text-4xl font-roboto">
                  {content["about-us-section-2"]?.title || "Our Mission"}
                </p>
                <hr className="w-[60%] border-t-2 border-[#EFDA55]" />
              </div>
              <p
                className="font-inter mt-2 text-base md:text-base text-justify w-full max-w-3xl"
                dangerouslySetInnerHTML={{
                  __html: content["about-us-section-2"]?.description || "Loading content...",
                }}
              />
            </div>
            <div className="flex flex-col items-center text-white mt-8 px-4">
              <div className="flex justify-center items-center w-full max-w-7xl space-x-2">
                <hr className="w-[60%] border-t-2 border-[#EFDA55]" />
                <p className="Vision-tagline text-4xl font-roboto">{content["about-us-section-3"]?.title || "Our Vision"}</p>
              </div>
              <p className="font-inter mt-2 text-base md:text-base text-justify w-full max-w-3xl"
                dangerouslySetInnerHTML={{
                  __html: content["about-us-section-3"]?.description || "Loading content...",
                }}
              />
            </div>
          </div>
        </section>


        {/* History Section */}
        <div className="py-6 sm:py-8">
          <div className="mx-auto max-w-screen-xl px-4 md:px-8">
            <div className="grid gap-8 md:grid-cols-2 lg:gap-12 items-center">
              <div className="h-64 overflow-hidden bg-slate-300 rounded-lg md:h-auto">
                <img
                  src={content["about-us-section-4"]?.image}
                  className="h-[350px] w-full object-cover object-center"
                  alt={content["about-us-section-4"]?.title || "History"}
                />
              </div>
              <section id="history" ref={historyRef} className="md:pt-4">
                <p className="text-center md:text-left font-bold font-roboto text-white text-4xl mb-4">
                  {content["about-us-section-4"]?.title || "History"}
                </p>
                <p
                  className="mb-6 text-white sm:text-lg text-base text-justify font-inter"
                  dangerouslySetInnerHTML={{
                    __html: content["about-us-section-4"]?.description || "Loading content...",
                  }}
                />
              </section>
            </div>
          </div>
        </div>
      </div >
    </section >
  );
}

export default AboutUs;
